import { Box, Grid, GridItem, SimpleGrid } from "@chakra-ui/react";
import { navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { ReactElement } from "react";
import { MyNavbar } from "../components/common/navbar/MyNavbar";
import { ExpandedIntroduction } from "../components/home/Introductions";
import { CategoryCard } from "../components/home/showcase/CatetgoryCard";
import { BackgroundBlue, BackgroundYellow } from "../components/styled/Backgrounds";
import { Title } from "../components/styled/Text";

export default function page(): ReactElement {
  return (
    <Box>
      <MyNavbar />
      <CategoryCard staticImage={<StaticImage src="../images/FireBoy.jpg" alt={""} />} category={{ name: "Player", url: "#player" }} />
      <CategoryCard staticImage={<StaticImage src="../images/WaterGirl.jpg" alt="" />} category={{ name: "Game Master", url: "#game_master" }} />
      <BackgroundYellow>
        <Title text="Benefits" />
      </BackgroundYellow>
      <ExpandedIntroduction />
    </Box>
  );
}

// Styled backgrounds to be used in pages
import react from "react";
import styled from "styled-components";

const Background = styled.div`
  min-height: 100vh;
`;

export const BackgroundYellow = styled.div`
  background-size: 100% 100%;
  background-position: 0px 0px, 0px 0px, 0px 0px, 0px 0px, 0px 0px;
  background-image: repeating-linear-gradient(315deg, #ffaf002e 92%, #073aff00 100%),
    repeating-radial-gradient(75% 75% at 238% 218%, #ffaa0112 30%, #bf931214 43%), radial-gradient(99% 30% at 35% 20%, #fff900ff 0%, #073aff00 100%),
    radial-gradient(99% 99% at 21% 78%, #ffd900ff 0%, #f6e78b00 100%), radial-gradient(160% 154% at 711px -303px, #ffe500ff 76%, #ffc100ff 100%);
  min-height: 100vh;
`;

export const BackgroundHomepage = styled.div`
  background: rgb(9, 89, 210);
  background: radial-gradient(circle, rgba(9, 89, 210, 1) 53%, rgba(0, 80, 231, 1) 81%);
`;

const OldBlue = styled(Background)`
  background: #0be2d4;
  background: -webkit-linear-gradient(top, #0be2d4, #0b82cf);
  background: -moz-linear-gradient(top, #0be2d4, #0b82cf);
  background: linear-gradient(to bottom, #0be2d4, #0b82cf);
`;

export const BackgroundBlue = styled(Background)`
  background: #02fff3;
`;

// #0b44cc
export const BackgroundDarkBlue = styled(Background)`
  background: #0be2d4;
  background: -webkit-linear-gradient(top, #00ccff, #6600ff);
  background: -moz-linear-gradient(top, #00ccff, #6600ff);
  background: linear-gradient(to bottom, #00ccff, #6600ff);
`;

export const BackgroundOrange = styled(Background)`
  background: #ffc201;
  background: -webkit-radial-gradient(top, #ffc201, #f62a03);
  background: -moz-radial-gradient(top, #ffc201, #f62a03);
  background: radial-gradient(to bottom, #ffc201, #f62a03);
`;

export const BackgroundPurple = styled(Background)`
  background: #8e3bf7;
  background: -webkit-linear-gradient(top, #8e3bf7, #f701a0);
  background: -moz-linear-gradient(top, #8e3bf7, #f701a0);
  background: linear-gradient(to bottom, #8e3bf7, #f701a0);
`;

export const BackgroundWhite = styled(Background)`
  background: white;
`;
